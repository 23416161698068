import React, { useState, useEffect } from 'react';

const BLEConnection = () => {
    const [batteryLevel, setBatteryLevel] = useState(null);
    const [error, setError] = useState(null);

    const connectToDevice = async () => {
        try {
            console.log('Requesting Bluetooth Device...');
            const device = await navigator.bluetooth.requestDevice({
                filters: [{ services: ['battery_service'] }]
            });

            console.log('Connecting to GATT Server...');
            const server = await device.gatt.connect();

            console.log('Getting Battery Service...');
            const service = await server.getPrimaryService('battery_service');

            console.log('Getting Battery Level Characteristic...');
            const characteristic = await service.getCharacteristic('battery_level');

            characteristic.addEventListener('characteristicvaluechanged', handleCharacteristicValueChanged);
            await characteristic.startNotifications();

            console.log('Reading Battery Level...');
            const value = await characteristic.readValue();
            const batteryLevel = value.getUint8(0);
            console.log(`Battery Level is ${batteryLevel}%`);
            setBatteryLevel(batteryLevel);
            setError(null);
        } catch (error) {
            console.error('Error:', error);
            setError(error.message);
        }
    };

    const handleCharacteristicValueChanged = (event) => {
        const value = event.target.value;
        const batteryLevel = value.getUint8(0);
        console.log(`Battery Level changed to ${batteryLevel}%`);
        setBatteryLevel(batteryLevel);
    };

    return (
        <div>
            <h1>BLE Device Connection</h1>
            <button onClick={connectToDevice}>Connect to BLE Device</button>
            {batteryLevel !== null && <p>Battery Level: {batteryLevel}%</p>}
            {error && <p>Error: {error}</p>}
        </div>
    );
};

export default BLEConnection;
